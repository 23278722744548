import { APP_MENU_TOGGLE, APP_REDIRECT_ON, APP_REDIRECT_OFF } from '@store/actionTypes'

const initialState = {
  collapsed: false,
  redirect: {
    show: false,
    to: ''
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_MENU_TOGGLE:
      return {
        ...state,
        collapsed: !state.collapsed
      }
    case APP_REDIRECT_ON:
      return {
        ...state,
        redirect: {
          show: true,
          to: action.payload
        }
      }
    case APP_REDIRECT_OFF:
      return {
        ...state,
        redirect: {
          show: false,
          to: ''
        }
      }
    default:
      return state
  }
}

export default reducer
