import React from 'react'

import style from './style.module.css'

const NoMatch = ({ history, location }) => {
  return (
    <div className={style.main}>
      <div className={style.content}>
        <h1>
        404
        </h1>
        <h3>
        Looks like you got lost
        </h3>
        <p>
        The page youre looking for doesnt exist or has been moved.
        </p>
        <button type='button' onClick={() => { history.push('/') }}>
          Go to Home
        </button>
      </div>

      <div className='iso404Artwork' />
    </div>
  )
}

export default NoMatch
