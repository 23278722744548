import React from 'react'
import { Provider } from 'react-redux'
import { Spin } from 'antd'
import { PersistGate } from 'redux-persist/integration/react'

import { store, persistor } from '@store'

import Route from '@component/Route'
import '@theme/css/style.css'

const Loader = () => (<div style={{
  textAlign: 'center',
  borderRadius: '4px',
  marginBottom: '20px',
  padding: '30px 50px',
  margin: '20px 0'
}}>
  <Spin />
</div>)

const App = () => (
  <Provider store={store}>
    <PersistGate
      loading={<Loader />}
      persistor={persistor}
    >
      <Route />
    </PersistGate>
  </Provider>
)

export default App
