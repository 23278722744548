import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_UPDATE, AUTH_UPDATE_PERMISSION } from '@store/actionTypes'

const initialState = {
  isLoggedIn: false,
  user: {},
  permissions: {}
}

const login = (state, action) => {
  state = { ...initialState }
  state.isLoggedIn = true
  state.user = action.user
  state.permissions = action.permissions

  return state
}

const update = (state, action) => {
  state.user = action.user
  return state
}

const updatePermission = (state, action) => {
  return Object.assign({}, state, {
    permissions: action.permissions
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_LOGIN:
      return login(state, action)
    case AUTH_UPDATE:
      return update(state, action)
    case AUTH_UPDATE_PERMISSION:
      return updatePermission(state, action)
    case AUTH_LOGOUT:
      return Object.assign({}, state, initialState)
    default:
      return state
  }
}

export default reducer
