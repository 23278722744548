import React from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { appRedirectOff } from '@store/actions/app'

const AppRedirect = (props) => {
  props.history.listen((location, action) => {
    if (location.pathname === props.app.redirect.to) {
      props.appRedirectOff()
    }
  })
  return props.app.redirect.show && props.location.pathname !== props.app.redirect.to ? <Redirect
    from={props.location.pathname}
    to={props.app.redirect.to}
  /> : null
}

const mapStateToProps = (state) => ({
  app: state.app
})

const mapDispatchToProps = { appRedirectOff }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AppRedirect))
