import { createStore, applyMiddleware } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import thunk from 'redux-thunk'

import reducers from './reducers'

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['auth']
}
const persistReducers = persistReducer(persistConfig, reducers)

let composeEnhancers = (c) => c
const middleware = [thunk]
if (process.env.NODE_ENV === 'development') {
  middleware.push(require('redux-logger').createLogger())

  if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  }
}
const store = createStore(persistReducers, composeEnhancers(applyMiddleware(...middleware)))
const persistor = persistStore(store)

export { store, persistor }
