import React, { lazy, Suspense } from 'react'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'

import { loader } from './utilities'
import AppRedirect from '@component/AppRedirect'
import NoMatch from '@screen/NoMatch'

import { store } from '@store'

const Home = lazy(() => import('@screen/Home')) // eslint-disable-line
const Login = lazy(() => import('@screen/Login'))
const Logout = lazy(() => import('@screen/Logout'))
const Auth = lazy(() => import('@screen/Auth'))

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const isLoggedIn = store.getState().auth.isLoggedIn
  return <Route
    {...rest}
    render={props =>
      isLoggedIn ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }
          }}
        />
      )
    }
  />
}

const Routes = (props) => (
  <BrowserRouter basename={'/html/'}>
    <AppRedirect />
    <Suspense fallback={loader}>
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path={'/login'} component={Login} />
        <Route exact path={'/logout'} component={Logout} />
        <RestrictedRoute path={'/auth'} component={Auth} />
        <Route component={NoMatch} />
      </Switch>
    </Suspense>
  </BrowserRouter>
)

export default Routes
