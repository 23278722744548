import { APP_MENU_TOGGLE, APP_REDIRECT_ON, APP_REDIRECT_OFF } from '@store/actionTypes'

export const appMenuToggle = () => ({
  type: APP_MENU_TOGGLE
})

export const appRedirectOn = (payload) => ({
  type: APP_REDIRECT_ON,
  payload
})

export const appRedirectOff = (payload) => ({
  type: APP_REDIRECT_OFF
})
